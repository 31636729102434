/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import { sessionStorage } from "../../../assets/myjs/glovar";
import axios from "axios";
export default {
  name: "ywProject",
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: -1,
      limit: 20,
      total: "",
      solId: ""
    };
  },
  created() {
    setTimeout(() => {
      this.moreLoad();
    }, 2000);
  },
  methods: {
    onClickLeft() {
      this.$router.push("/index");
    },
    moreLoad() {
      this.page += 1;
      this.onLoad();
    },
    onLoad() {
      var _this = this;
      let url = _baseUrl + `/mobile/ywgz/findAllTbGlXx.do?pageIndex=${this.page}&pageSize=${this.limit}`;
      axios.post(url).then(res => {
        _this.list = _this.list.concat(res.data.rowList);
        _this.loading = false;
        _this.total = res.data.total;
        if (_this.list.length >= _this.total) {
          _this.finished = true;
        } else {
          _this.finished = false;
        }
      });
    },
    onInfo(item) {
      sessionStorage.setItem("xmbhZB", item);
      this.$router.push("/zbInfoHom");
    }
  }
};